"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertMetricValue = convertMetricValue;
exports.formatMetricValue = formatMetricValue;
exports.getUnit = getUnit;
exports.formatConsumption = formatConsumption;
exports.MetricsType = void 0;

var _d3Format = require("d3-format");

/* eslint-disable no-eq-null */
// Keep values equivalent to API keys
let MetricsType;
exports.MetricsType = MetricsType;

(function (MetricsType) {
  MetricsType["Electricity"] = "Electricity";
  MetricsType["Gas"] = "Gas";
  MetricsType["Water"] = "Water";
})(MetricsType || (exports.MetricsType = MetricsType = {}));

const precisionFixed001 = String((0, _d3Format.precisionFixed)(0.01));
const precisionFixed01 = String((0, _d3Format.precisionFixed)(0.1));
const precisionFixed1 = String((0, _d3Format.precisionFixed)(1));
const formatNumLessThanThreeDigits = (0, _d3Format.format)(`.${precisionFixed001}f`);
const formatNumLessThanFourDigits = (0, _d3Format.format)(`.${precisionFixed01}f`);
const formatNumGreaterThanFourDigits = (0, _d3Format.format)(`,.${precisionFixed1}f`);
const formatNumZero = (0, _d3Format.format)(`.${precisionFixed1}f`);
/**
 * Given a total metric value, convert it to be used alongside the output of
 * `getUnit`.
 */

function convertMetricValue(num, metricsType) {
  switch (metricsType) {
    case MetricsType.Gas:
      return num;

    case MetricsType.Water:
    case MetricsType.Electricity:
      {
        if (num >= 1000000) return num / 1000000;
        return num >= 1000 ? num / 1000 : num;
      }
  }
}
/** Implemented in Aperture */


function formatMetricValue(num) {
  if (num === 0) {
    return formatNumZero(num);
  }

  if (num <= 99.99) {
    return formatNumLessThanThreeDigits(num);
  }

  if (num <= 999.99) {
    return formatNumLessThanFourDigits(num);
  }

  return formatNumGreaterThanFourDigits(num);
}
/**
 * Given a total value and the metric type, return the correct unit.
 *
 * NOTE: This is used in tandem with `convertMetricValue` to represent the
 * change in units at specific thresholds.
 * */


function getUnit(num, metricsType) {
  switch (metricsType) {
    case MetricsType.Gas:
      return 'GJ';

    case MetricsType.Water:
      {
        if (num >= 1000000) return 'GL';
        return num >= 1000 ? 'ML' : 'kL';
      }

    case MetricsType.Electricity:
    default:
      {
        if (num >= 1000000) return 'GWh';
        return num >= 1000 ? 'MWh' : 'kWh';
      }
  }
}

function formatConsumption(totalValue, type, estimatedValue, actualValue) {
  const unit = getUnit(totalValue, type);
  const formattedEstimatedValue = estimatedValue != null ? formatMetricValue(convertMetricValue(estimatedValue, type)) : undefined;
  const formattedActualValue = actualValue != null ? formatMetricValue(convertMetricValue(actualValue, type)) : undefined;
  return {
    formattedTotalValue: formatMetricValue(convertMetricValue(totalValue, type)),
    formattedUnit: unit,
    formattedEstimatedValue,
    formattedActualValue
  };
}