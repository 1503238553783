"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCurrencySymbol = useCurrencySymbol;

var _react = require("react");

var _store = require("../redux/store");

var _reselect = require("reselect");

var _user = require("../redux/selectors/user");

var _lodash = _interopRequireDefault(require("lodash.isequal"));

const projectsSelector = (0, _reselect.createSelector)([_user.selectUserProjects], projects => Object.values(projects), {
  memoizeOptions: {
    resultEqualityCheck: _lodash.default
  }
});
/**
 * This hook returns the currency symbol for a provided project key by finding
 * the Forge UUID first from the key via the user's `portfolio.projects`
 * property, then checking which UUID should use which symbol. This avoids the
 * problem of one organisation being across multiple regions (Google UK vs.
 * Google US).
 *
 * It's simpler in Bonfire to find the UUID via the project key as most API
 * responses for actions do NOT include the Forge UUID but will have the Project
 * Key. The alternative is updating each view the previous `getCurrencySymbol`
 * function is used in and drilling the UUID through multiple layers of props.
 *
 * i.e. We would be running a project find for each instance _anyway_. The
 * theory is it's easier to update instances of `getCurrencySymbol` with
 * `useCurrencySymbol` only rather than find all the prop drilling paths for the
 * UUID and follow them all the way down.
 */

function useCurrencySymbol(projectKey) {
  const organisations = (0, _store.useAppSelector)(projectsSelector);
  const organisation = (0, _react.useMemo)(() => {
    return Object.values(organisations).find(organisation => organisation.jira_key === projectKey);
  }, [organisations, projectKey]);

  switch (organisation === null || organisation === void 0 ? void 0 : organisation.forge_id) {
    case 'a879e9d7-ff14-4776-86cb-537fd870b186': // GOOGLE (UK)

    case '6cee7423-112d-4831-90b7-c85cd59d8928': // ASHBY

    case '33e8d48e-0a79-4ca5-a8d4-5471fb1945fc': // PRIM (Optimised)

    case 'e78e7129-7786-4429-939b-9bcc354a5556': // LZI

    case '38f95ebd-ba55-425b-a3aa-be3f7deaa829': // LCC

    case 'afb4e8f8-966a-4161-aae0-02d9448a1b97': // VQSH

    case 'd8b1981f-ff85-4a25-9234-8df7b6e5f5b8': // NEXT

    case 'c67ae66d-be11-4188-8ca3-3bd8eed48ce0':
      // KCE
      return '£';

    case 'c3a4f3b5-4231-4c4f-b772-fe0e5889c85c': // DISNEY

    case '34ff5537-94ac-4d5b-a598-d86bff035491':
      // PRIMEU
      return '€';

    case 'f1fca5a8-2c49-458e-be33-68a0cb63da95': // GOOGLE (US)

    default:
      return '$';
  }
}