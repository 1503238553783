"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectUserProjects = exports.selectUserTrackingState = exports.selectUserEmail = exports.selectUserDisplayName = exports.selectUserId = void 0;

const selectUserId = state => state.auth.user.forgeId;

exports.selectUserId = selectUserId;

const selectUserDisplayName = state => {
  var _state$auth$user$disp;

  return (_state$auth$user$disp = state.auth.user.display_name) !== null && _state$auth$user$disp !== void 0 ? _state$auth$user$disp : '';
};

exports.selectUserDisplayName = selectUserDisplayName;

const selectUserEmail = state => {
  var _state$auth$user$emai;

  return (_state$auth$user$emai = state.auth.user.email) !== null && _state$auth$user$emai !== void 0 ? _state$auth$user$emai : '';
};

exports.selectUserEmail = selectUserEmail;

const selectUserTrackingState = state => state.auth.user.optOutUserTracking;

exports.selectUserTrackingState = selectUserTrackingState;

const selectUserProjects = state => {
  var _state$auth$user$port, _state$auth$user$port2;

  return (_state$auth$user$port = (_state$auth$user$port2 = state.auth.user.portfolio) === null || _state$auth$user$port2 === void 0 ? void 0 : _state$auth$user$port2.projects) !== null && _state$auth$user$port !== void 0 ? _state$auth$user$port : {};
};

exports.selectUserProjects = selectUserProjects;