"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SETTINGS_INSIGHTS = exports.SETTINGS_MONITORING = exports.GROUP_ADMIN = exports.USER_ADMIN = exports.USER_PROFILE = exports.VIEW_ACTION = exports.ACTIONS = exports.MONITORING_EQUIPS = exports.INSIGHT_EQUIPS = exports.MONITORING = exports.INSIGHTS = exports.ANALYSIS = exports.InsightType = void 0;
let InsightType; // Analysis routes -> Insights and Monitoring

exports.InsightType = InsightType;

(function (InsightType) {
  InsightType["Insights"] = "insights";
  InsightType["Monitoring"] = "monitoring";
})(InsightType || (exports.InsightType = InsightType = {}));

const ANALYSIS = `/analysis`;
exports.ANALYSIS = ANALYSIS;
const INSIGHTS = `/analysis/${InsightType.Insights}`;
exports.INSIGHTS = INSIGHTS;
const MONITORING = `/analysis/${InsightType.Monitoring}`;
exports.MONITORING = MONITORING;
const INSIGHT_EQUIPS = `/analysis/${InsightType.Insights}/:syncoId/:ruleId`;
exports.INSIGHT_EQUIPS = INSIGHT_EQUIPS;
const MONITORING_EQUIPS = `/analysis/${InsightType.Monitoring}/:syncoId/:ruleId`; // Actions routes

exports.MONITORING_EQUIPS = MONITORING_EQUIPS;
const ACTIONS = '/actions';
exports.ACTIONS = ACTIONS;
const VIEW_ACTION = '/actions/:actionId'; // Admin routes

exports.VIEW_ACTION = VIEW_ACTION;
const USER_PROFILE = '/profile';
exports.USER_PROFILE = USER_PROFILE;
const USER_ADMIN = '/users';
exports.USER_ADMIN = USER_ADMIN;
const GROUP_ADMIN = '/groups'; // Settins routes

exports.GROUP_ADMIN = GROUP_ADMIN;
const SETTINGS_MONITORING = `/settings/${InsightType.Monitoring}`;
exports.SETTINGS_MONITORING = SETTINGS_MONITORING;
const SETTINGS_INSIGHTS = `/settings/${InsightType.Insights}`;
exports.SETTINGS_INSIGHTS = SETTINGS_INSIGHTS;